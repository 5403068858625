
const state = {
  AuthError: {
    active: false,
    message: '',
  },
};
const getters = {};

const mutations = {
  SET_AUTH_ERROR(state, payload) {
    state.AuthError = payload;
  },
};

const actions = {
  setAuthError({commit}, payload) {
    commit('SET_AUTH_ERROR', payload);
  },
};


export default {
  state,
  mutations,
  actions,
  getters,
};
