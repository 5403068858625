<template>
  <aside
    class="right-sidebar"
    :class="computedClass"
    :style="computedStyle"
  >
    <div class="app-sidebar__menu">
      <div v-if="$store.state.evaluation.activeEvalItem">
        <eval-editor />
      </div>
    </div>
  </aside>
</template>

<script>
import {ColorThemeMixin} from '../../../services/vuestic-ui';
import evalEditor from '../../eval/sideEditor.vue';
export default {
  name: 'AppSidebarRight',
  inject: ['contextConfig'],
  mixins: [ColorThemeMixin],
  components: {
    evalEditor,
  },
  computed: {
    computedClass() {
      return {
        'app-sidebar--minimized': this.minimized,
      };
    },
    computedStyle() {
      return {
        backgroundColor: this.contextConfig.invertedColor ? 'white' : this.colorComputed,
      };
    },
  },
};
</script>

<style>
.right-sidebar {
  overflow: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16rem;
  flex: 0 0 16rem;
}
</style>
