import firebase from 'firebase';
const state = {
  categories: [],
  evaluation: [],
  activeEvalItem: {id: 0},
  evalEditPanel: false,
  title: '',
  locked: false,
};
const getters = {
  lockedEval(state) {
    return state.locked ? true : false;
  },
};
const mutations = {
  SET_EVALUATION(state, payload) {
    state.evaluation = payload;
  },
  SET_EVALUATION_CATEGORIES(state, payload) {
     //apend the new category to the array
    state.categories =  payload;
  },
  SET_EVAL_ITEM(state, payload) {
    state.activeEvalItem = payload;
  },
  CLEAR_EVAL_ITEM(state) {
    state.activeEvalItem = {id: 0};
  },
  TOGGLE_EVAL_EDIT_PANEL(state, payload) {
    state.evalEditPanel = payload;
  },
  ADD_SECTION(state, payload) {
    const newArray = [
      ...state.evaluation.slice(0, payload.saveIndex),
      payload.component,
      ...state.evaluation.slice(payload.saveIndex),
    ];

    state.evaluation = newArray;
  },
  REMOVE_SECTION(state, payload) {
    state.evaluation = state.evaluation.filter((element, index)=>{
      return index!==payload;
    });
  },
  ADD_QUESTION_TO_SECTION(state, payload) {
    const sectionCopy = JSON.parse(JSON.stringify(state.evaluation.find((section)=>{
      return section.id === payload.sectionId;
    })));
    const questionArray = [
      ...sectionCopy.data.questions.slice(0, payload.index),
      payload.component,
      ...sectionCopy.data.questions.slice(payload.index),
    ];
    sectionCopy.data.questions = questionArray;

    const sectionIndex = state.evaluation.findIndex((section)=>{
      return section.id === payload.sectionId;
    });

    const newEval = [
      ...state.evaluation.slice(0, sectionIndex),
      sectionCopy,
      ...state.evaluation.slice(sectionIndex+1),
    ];
    state.evaluation = newEval;
  },
  REMOVE_QUESTION(state, payload) {
    const sectionIndex = state.evaluation.findIndex((section)=>{
      return section.id===payload.sectionId;
    });
    const sectionCopy = JSON.parse(JSON.stringify(state.evaluation.find((section)=>{
      return section.id === payload.sectionId;
    })));

    sectionCopy.data.questions.splice(payload.questionIndex, 1);


    const newEval = [
      ...state.evaluation.slice(0, sectionIndex),
      sectionCopy,
      ...state.evaluation.slice(sectionIndex+1),
    ];
    state.evaluation = newEval;
  },
  UPDATE_EVAL_ITEM(state, payload) {
    const copiedPayload = JSON.parse(JSON.stringify(payload));
    const editableSectionIndex = state.evaluation.findIndex((section)=>{
      return section.id === state.activeEvalItem;
    });
    if (editableSectionIndex === -1) {
      // it is a question inside a section

      let foundSectionIndex = 0;
      const sectionCopy = JSON.parse(JSON.stringify(state.evaluation.find((section, sectionIndex)=>{
        if (section.id === state.activeEvalItem) {
          return true;
        } else {
          return section.data.questions.find((question)=>{
            if (question.id === state.activeEvalItem) {
              foundSectionIndex = sectionIndex;
              return true;
            }
          });
        }
      })));

      const questionIndex = sectionCopy.data.questions.findIndex((question) => {
        return question.id === state.activeEvalItem;
      });
      sectionCopy.data.questions[questionIndex] = copiedPayload;

      const newEval = [
        ...state.evaluation.slice(0, foundSectionIndex),
        sectionCopy,
        ...state.evaluation.slice(foundSectionIndex+1),
      ];
      state.evaluation = newEval;
    } else {
      // it is a section that needs updating

      const newEval = [
        ...state.evaluation.slice(0, editableSectionIndex),
        copiedPayload,
        ...state.evaluation.slice(editableSectionIndex+1),
      ];
      state.evaluation = newEval;
    }
  },
  MOVE_SECTION(state, payload) {
    const newEval = [...state.evaluation];
    const sectionIndex = state.evaluation.findIndex((section)=>{
      return section.id === state.activeEvalItem;
    });
    const item = newEval.splice(sectionIndex, 1)[0];
    const to = payload === 'up' ? sectionIndex-1 : sectionIndex+1;
    if (to === -1) {
      return;
    };
    newEval.splice(to, 0, item);
    state.evaluation = newEval;
    // return newArr;
  },

  MOVE_QUESTION(state, payload) {
    let foundSectionIndex = 0;
    const sectionCopy = JSON.parse(JSON.stringify(state.evaluation.find((section, sectionIndex)=>{
      if (section.id === state.activeEvalItem) {
        return true;
      } else {
        return section.data.questions.find((question)=>{
          if (question.id === state.activeEvalItem) {
            foundSectionIndex = sectionIndex;
            return true;
          }
        });
      }
    })));

    const questionIndex = sectionCopy.data.questions.findIndex((question) => {
      return question.id === state.activeEvalItem;
    });

    const item = sectionCopy.data.questions.splice(questionIndex, 1)[0];
    const to = payload === 'up' ? questionIndex-1 : questionIndex+1;
    if (to === -1) {
      return;
    };
    sectionCopy.data.questions.splice(to, 0, item);

    const newEval = [
      ...state.evaluation.slice(0, foundSectionIndex),
      sectionCopy,
      ...state.evaluation.slice(foundSectionIndex+1),
    ];
    state.evaluation = newEval;
    // console.log(sectionCopy, foundSectionIndex);
  },
  SET_SHOW_REQUIREMENTS(state, payload) {
    let foundSectionIndex = 0;
    const sectionCopy = JSON.parse(JSON.stringify(state.evaluation.find((section, sectionIndex)=>{
      if (section.id === state.activeEvalItem) {
        return true;
      } else {
        return section.data.questions.find((question)=>{
          if (question.id === state.activeEvalItem) {
            foundSectionIndex = sectionIndex;
            return true;
          }
        });
      }
    })));

    const question = sectionCopy.data.questions.find((question) => {
      return question.id === state.activeEvalItem;
    });

    if(payload == null) {
      question.showRequirements = null;
    } else {
      question.showRequirements = {
        'questionId': payload.questionId,
        'answerIndex': payload.answerIndex.id,
      };
    }

    const newEval = [
      ...state.evaluation.slice(0, foundSectionIndex),
      sectionCopy,
      ...state.evaluation.slice(foundSectionIndex+1),
    ];
    state.evaluation = newEval;
  },
  SET_TITLE(state, payload) {
    state.title = payload;
  },
  LOCK_EVAL(state) {
    state.locked = !state.locked;
    firebase.firestore().collection('evalTemplates').doc(state.evalId).update({
      locked: state.locked,
    });
  },
  SET_ID(state, payload) {
    state.evalId = payload;
  },
  SET_LOCK(state, payload) {
    state.locked = payload;
  },
};

const actions = {
  addSection({commit}, payload) {
    console.log('adding section');
    commit('ADD_SECTION', payload);
  },
  setCategories({commit}, payload) {
    commit('SET_EVALUATION_CATEGORIES', payload);
  },
  setEvaluation({commit}, payload) {
    commit('SET_EVALUATION', payload);
  },
  setEvalItem({commit}, payload) {
    commit('SET_EVAL_ITEM', payload);
    commit('SET_RIGHT_SIDEBAR', true, {root: true});
  },
  updateEvalItem({commit}, payload) {
    commit('UPDATE_EVAL_ITEM', payload);
  },
  clearEvalItem({commit}) {
    commit('CLEAR_EVAL_ITEM');
    commit('SET_RIGHT_SIDEBAR', false, {root: true});
  },
  removeEvalSection({commit}, payload) {
    commit('REMOVE_SECTION', payload);
  },
  removeQuestion({commit}, payload) {
    commit('CLEAR_EVAL_ITEM');
    commit('REMOVE_QUESTION', payload);
  },
  toggleEvalEditPanel({commit}, payload) {
    commit('TOGGLE_EVAL_EDIT_PANEL', payload);
  },
  addQuestionToSection({commit}, payload) {
    commit('ADD_QUESTION_TO_SECTION', payload);
  },
  addQuestionToIndex({commit}, payload) {
    // commit('ADD_QUESTION_TO_INDEX', payload);
  },
  setShowRequirements({commit}, payload) {
    commit('SET_SHOW_REQUIREMENTS', payload);
  },
  moveSection({commit}, payload) {
    commit('MOVE_SECTION', payload);
  },
  moveQuestion({commit}, payload) {
    commit('MOVE_QUESTION', payload);
  },
  setTitle({commit}, payload) {
    commit('SET_TITLE', payload);
  },
  toggleLock({commit}) {
    commit('LOCK_EVAL');
  },
  setLockState({commit}, payload) {
    console.log('setting lock state', payload);
    commit('SET_LOCK', payload);
  },
  setId({commit}, payload) {
    commit('SET_ID', payload);
  },
};


export default {
  state,
  mutations,
  actions,
  getters,
};
