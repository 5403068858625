<template>
  <div >
    <va-dropdown
      class="notification-dropdown"
      offset="0, 16px"
      boundary-body

    >
      <va-icon-notification
        slot="anchor"
        class="notification-dropdown__icon"
        :class="{'notification-dropdown__icon--unread': !allRead}"
        :color="contextConfig.invertedColor ? $themes.gray : 'white'"
      />
      <div class="notification-dropdown__content pl-1 pr-1 pt-2 pb-2">
     
        <div
          class="notification-dropdown__item row pt-1 pb-1 mt-2 mb-2"
          v-if="notifications.length === 0"
        >
          Nothing yet
        </div>
       
      
     
        <va-inner-loading :loading="busy">
          <div class="notificationScrollContainer" @scroll="handleScroll" ref="notificationContainer">
            <div
                v-for="option in notifications"
                :key="option.id"
                @click="handleAction(option)"
                class="mt-2 mb-2 mx-1  py-1 px-1"
                :class="{'notification-dropdown__item--unseen': !option.seen}"
              >
              <div
                class="notification-dropdown__item row pt-1 pb-1 mt-2 mb-2"
                :class="{'notification-dropdown__item--unread': option.unread, 'notification-dropdown__item--unseen': !option.seen}"
              >
                <img
                  v-if="option.details.avatar"
                  class="mr-2 notification-dropdown__item__avatar"
                  :src="option.details.avatar"
                >
                <span
                  class="ellipsis"
                  style="max-width: 85%;"
                >
                  <span
                    class="text--bold"
                    v-if="option.details.author"
                  >{{ option.name }}</span> 
                  <span class="text--bold" v-else>{{ option.name || "N/A" }}</span>
                </span>
              </div>
              <div class="notification-timestamp">
                {{ prettyTimeForNotification(option.id) }}
              </div>
            </div>
          </div>
          <div class="row justify--space-between">
            <va-button
              class="ma-0 mb-2 mt-1"
              small
              @click="markAllAsSeen"
            >
              {{ $t('notifications.all') }}
            </va-button>
            <va-button
              class="ma-0 mb-2 mt-1"
              small
              outline
              @click="markAllAsRead"
              :disabled="allRead"
            >
              {{ $t('notifications.mark_as_read') }}
            </va-button>
          </div>
        </va-inner-loading>
        </div>
    </va-dropdown>
    <va-modal
    v-model="showNotificationLog"
    class="flex xs12"
    size="large"
    :hide-default-actions="true"
    :title="'History Logs'"
  >

  <div v-if="selectedLogData.type === 'Support'">
      <p><b>Information</b></p>
      <p>Area: {{ selectedLogData.name  }}</p>
      <p>Ticket Replied UID: {{ explodeTUID(selectedLogData.details.action, "NIHSS Support/") }}</p>
      <p>Ticket Type: {{ selectedLogData.details.ticketType }}</p>
      <p>Created At: {{ selectedLogData.createdAt  }}</p>
      <br/>
      <p><b>Replied By:</b></p>
      <p @click="closePopup()"> Id:  <router-link  :to="'/users/edituser/' + selectedLogData.details.author">{{ selectedLogData.details.author }}</router-link></p>
      <br/>
      <p><b>View Ticket Reply At:</b></p>
      <p @click="closePopup()"> <router-link  :to="`/tickets/${findMiddleware(selectedLogData.details.ticketType)}/${explodeTUID(selectedLogData.details.action, 'NIHSS Support/')}`" >Reply for Ticket #{{ explodeTUID(selectedLogData.details.action, "NIHSS Support/") }}</router-link></p>
      <br/>
      <p><b>Event</b></p>
      <p>Type: {{ selectedLogData.type  }}</p>
      <p>Target: {{ selectedLogData.details.action  }}</p>
      <hr/>
      <p></p>
      <div class="pt-2">
        <va-button
            slot="actions"
            @click="showNotificationLog = false"
            color="danger"
            class="mr-2"
        > Close </va-button>
      </div>
  </div>
  <div v-else>
      <p><b>Information</b></p>
      <p>Area: {{ selectedLogData.name  }}</p>
      <p>Created At: {{ selectedLogData.createdAt  }}</p>
      <br/>
      <p><b>Admin</b></p>
      <p @click="closePopup()"> Id:  <router-link  :to="'/users/edituser/' + selectedLogData.details.author">{{ selectedLogData.details.author }}</router-link></p>
      <br/>
      <p><b>Event</b></p>
      <p>Type: {{ selectedLogData.type  }}</p>
      <p>Target: {{ selectedLogData.details.action  }}</p>
      <hr/>
      <p></p>
        <div class="pt-2">
          <va-button
              slot="actions"
              @click="showNotificationLog = false"
              color="danger"
              class="mr-2"
          > Close </va-button>
        </div>
  </div>
      

        
  </va-modal>

  </div>


 

</template>

<script>
import {mapGetters} from 'vuex';
import VaIconNotification from '../../../../../iconset/VaIconNotification';
import {ColorThemeMixin} from '../../../../../services/vuestic-ui';

export default {
  name: 'NotificationDropdown',
  inject: ['contextConfig'],
  components: {
    VaIconNotification,
  },
  mixins: [ColorThemeMixin],
  data() {
    return {
      selectedLogData: {
        name: null,
        createdAt: null,
        details: {
            author: null,
            action: null,
            text: null,
        },
        type: null,

      },
      busy: false,
      showNotificationLog: false,
      computedOptions: [...this.options],
    };
  },
  props: {
    options: {
      type: Array,
      default: () => [
        {
          name: 'sentMessage',
          details: {name: null, avatar: 'https://picsum.photos/123'},
          unread: true,
          id: 1,
        },
        {
          name: 'uploadedZip',
          details: {
            name: 'Oleg M',
            avatar: 'https://picsum.photos/100',
            type: 'typography component',
          },
          unread: true,
          id: 2,
        },
        {
          name: 'startedTopic',
          details: {name: 'Andrei H', avatar: 'https://picsum.photos/24'},
          unread: true,
          id: 3,
        },
      ],
    },
  },
  computed: {
    ...mapGetters(['notifications', 'prettyTimeForNotification', 'allRead']),
  },
  methods: {
    handleScroll() {
    const container = this.$refs.notificationContainer;
      if (container.scrollTop > this.lastScrollTop) {
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
          this.loadMore();
        }
      }
      this.lastScrollTop = container.scrollTop;
    },
    async loadMore() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      await this.$store.dispatch('getMorePages')
      this.busy = false;
    },
    handleAction(option) {
      //format option.creationDate to YYYY-MM-DD 
      const date = new Date(option.creationDate.seconds * 1000 + option.creationDate.nanoseconds / 1000000);

      // Formatar a data como yyyy/mm/dd hh:mm:ss
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

      option.createdAt = formattedDate;
      this.selectedLogData = option;
      this.showNotificationLog = true;

      this.$store.dispatch('markAsRead', {
        id: option.id,
        type: option.type,
      });
    },
    async markAllAsRead() {
      this.$swal({
        title: 'Updating...',
        allowOutsideClick: false,
        onOpen: () => {
          this.$swal.showLoading();
        },
      });
      await this.$store.dispatch('markAllAsRead');
      this.$swal.close();
      //swal success
      this.$swal({
        title: 'Success!',
        text: 'All Notifications marked as read',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false,
      });
    },
    async markAllAsSeen() {
      this.$swal({
        title: 'Updating...',
        allowOutsideClick: false,
        onOpen: () => {
          this.$swal.showLoading();
        },
      });
      await this.$store.dispatch('markAsSeen');
      this.$swal.close();
      //swal success
      this.$swal({
        title: 'Success!',
        text: 'All Notifications marked as seen',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false,
      });
    },
    findMiddleware(type){
      if(type === 'Support'){
        return 'nihss-tickets';
      }else{
        return 'evaluation-tickets';
      }
    },
    closePopup() {
      this.showNotificationLog = false;
    },
    explodeTUID(str, separator) {
      return str.split(separator)[1];
    },
  },
};
</script>

<style lang="scss">

.notification-timestamp {
  font-size: small;
  margin-top: -10px;
  margin-bottom: 10px;
}

.notificationScrollContainer {
  max-height: 350px;
  overflow-y: scroll;
}

.notification-dropdown {
  cursor: pointer;

  .notification-dropdown__icon {
    position: relative;
    display: flex;
    align-items: center;

    &--unread::before {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      top: -0.5rem;
      background-color: $brand-danger;
      height: 0.375rem;
      width: 0.375rem;
      margin: 0 auto;
      border-radius: 0.187rem;
    }
  }

  &__content {
    background-color: $dropdown-background;
    box-shadow: $gray-box-shadow;
    border-radius: 0.5rem;
    max-width: 19rem;
  }

  &__item {
    cursor: pointer;
    margin-bottom: 0.75rem;
    color: $brand-secondary;
    flex-wrap: nowrap;
    position: relative;

    &--unseen {
      background: #d3eaf7;
      border-radius: 11px;
    }

    &--unread {
      color: $vue-darkest-blue;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        height: 0.375rem;
        width: 0.375rem;
        background-color: $brand-danger;
        margin: auto;
        border-radius: 0.187rem;
      }
    }

    &:hover {
      color: $vue-green;
    }

    &__avatar {
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
    }
  }

  .va-dropdown__anchor {
    display: inline-block;
  }
}
</style>
