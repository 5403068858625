import Vue from 'vue';
import Vuex from 'vuex';
import VuexI18n from 'vuex-i18n'; // load vuex i18n module
import app from './modules/app';
import evaluation from './modules/evaluation';
import evalPreview from './modules/evalPreview';
import auth from './modules/auth';
import notifications from './modules/notifications';

import * as appGetters from './getters';

const getters = {
  ...appGetters,
  // ...evaluation.getters,
};

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: true, // process.env.NODE_ENV !== 'production',
  getters,
  modules: {
    app,
    auth,  
    evaluation,
    evalPreview,
    notifications,
  },
  state: {},
  mutations: {},
});

Vue.use(VuexI18n.plugin, store);

export default store;
