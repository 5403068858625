const config = (state) => state.app.config;
const palette = (state) => state.app.config.palette;
const isLoading = (state) => state.app.isLoading;

const status = (state) => state.app.status;
const user = (state) => state.app.user;
const error = (state) => state.app.error;

export {
  config,
  palette,
  isLoading,
  status,
  user,
  error,
};
