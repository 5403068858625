import firebase from 'firebase';
import moment from 'moment';


const getters = {
  notifications: state => state.notifications,
  prettyTimeForNotification: state => id => {
    const notification = state.notifications.find(notification => notification.id === id);
    return moment(new Date(notification.creationDate.seconds * 1000)).fromNow();
  },
  allRead: state => {
    return state.notifications.every(notification => !notification.unread);
  },
  unseenCount: state => {
    return state.notifications.filter(notification => !notification.seen).length;
  },
};
const state = {
  notifications: [],
};

const mutations = {
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload;
  },
  READ_AUTOMATIC_NOTIFICATIONS(state, payload) {
    state.notifications = state.notifications.map(notification => {
      if (notification.type === 'Automatic' && notification.id === payload.id) {
        return {
          ...notification,
          seen: true,
          unread: false,
        };
      } else {
        return notification;
      }
    });
  },
  ADD_NOTIFICATIONS(state, payload) {
    state.notifications.push(...payload);
  },
};


const CheckForUnassignedTickets = async () => {
  let data = await firebase.firestore().collection('evalTickets').where('adminID', '==', false).get()
  if (data.docs.length > 0) {
    //get data of the first ticket
    return {
      creationDate: firebase.firestore.Timestamp.now(),
      details: {
        action: `There are ${data.docs.length} unassigned tickets`,
        author: "System",
        text: `Automatic notification generated by the system.`,
      },
      id: data.docs[0].id,
      name: 'System - Unassigned Tickets',
      readBy: [],
      seen: false,
      seenBy: [],
      type: "Automatic",
      unread: true,
    }
  }
  return null
};



const actions = {
  async fetchNotifications({ commit, state }) {
    try {
      const doc = await firebase.firestore().collection('adminNotificationPreferences').doc(this.state.app.user.uid).get();
      const preferenceData =  doc.exists ? doc.data() : {};
      
      const snapshot = await firebase
        .firestore()
        .collection('Notifications')
        .where('type', 'in', ['Editor', 'Organization', 'System', 'User', 'Support',  'Quote'])
        .orderBy('creationDate', 'desc')
        .limit(10)
        .get();

      const notifications = [];
      snapshot.forEach(notification => {
        const data = notification.data();
        if (data?.seenBy?.includes(this.state.app.user.uid)) {
          data.seen = true;
        }
        if (data?.readBy?.includes(this.state.app.user.uid)) {
          data.unread = false;
        }

        if (
          (preferenceData?.editor && data.type === 'Editor') ||
          (preferenceData?.organization && data.type === 'Organization') ||
          (preferenceData?.system && data.type === 'System') ||
          (preferenceData?.support && data.type === 'Support') ||
          (preferenceData?.user && data.type === 'User') ||
          (preferenceData?.user && data.type === 'Quote')
        ) {
          notifications.push({ id: notification.id, ...data });
        }
      });

      let notification = await CheckForUnassignedTickets();
      if (notification) {
        notifications.push(notification);
      }

      notifications.sort((a, b) => b.creationDate.seconds - a.creationDate.seconds);
      commit('SET_NOTIFICATIONS', notifications);
    } catch (error) {
      console.error('Erro ao buscar notificações:', error);
      throw error;
    }
  },
  async getMorePages({ commit, state }, page) {
    try {
      const doc = await firebase.firestore().collection('adminNotificationPreferences').doc(this.state.app.user.uid).get();
      const preferenceData =  doc.exists ? doc.data() : {};
      const startAfter = state.notifications[state.notifications.length - 1]?.creationDate;

      if (!startAfter) {
        console.warn('There are no more notifications to fetch');
        return;
      }

      const snapshot = await firebase
        .firestore()
        .collection('Notifications')
        .where('type', 'in', ['Editor', 'Organization', 'System', 'User', 'Support', 'Quote'])
        .orderBy('creationDate', 'desc')
        .startAfter(startAfter)
        .limit(10)
        .get();


      const notifications = [];
      snapshot.forEach(notification => {
        const data = notification.data();
        if (data?.seenBy?.includes(this.state.app.user.uid)) {
          data.seen = true;
        }
        if (data?.readBy?.includes(this.state.app.user.uid)) {
          data.unread = false;
        }

        if (
          (preferenceData?.editor && data.type === 'Editor') ||
          (preferenceData?.organization && data.type === 'Organization') ||
          (preferenceData?.system && data.type === 'System') ||
          (preferenceData?.support && data.type === 'Support') ||
          (preferenceData?.user && data.type === 'User') ||
          (preferenceData?.user && data.type === 'Quote')
        ) {
          notifications.push({ id: notification.id, ...data });
        }
      });

      commit('ADD_NOTIFICATIONS', notifications);
    } catch (error) {
      console.error('Erro ao buscar mais páginas de notificações:', error);
      throw error;
    }
  },
  markAsRead({ commit, getters }, payload) {
    if (payload.type === 'Automatic') {
      commit('READ_AUTOMATIC_NOTIFICATIONS', { id: payload.id });
    } else {
      firebase.firestore()
        .collection('Notifications')
        .doc(payload.id)
        .update({
          readBy: firebase.firestore.FieldValue.arrayUnion(this.state.app.user.uid)
        })
        .catch((error) => {
          this.$swal({
            title: 'Error',
            text: error.message,
            icon: 'error',
          });
        });
    }
  },
  async markAllAsRead({commit}, payload) {

    return new Promise(async (resolve, reject) => {
      //check in collection adminNotificationPreferences if the current user uid exists
      await firebase.firestore().collection('adminNotificationPreferences').doc(this.state.app.user.uid).get().then(async (doc)=>{
        if(doc.exists){
          const preferenceData = doc.data();
          /**
         * @title - Notifications V2 (Fetch)
         * @description - This is the new way of fetching notifications, it will fetch all notifications that are of type "System"
         */
          await firebase.firestore().collection('Notifications')
            .where('type', 'in', ['Editor', 'Organization', 'System', 'User', 'Support', 'Quote'])
            .orderBy('creationDate', 'desc')
            .get()
            .then((snapshot) => {
              snapshot.forEach((notification) => {
                const data = notification.data();
                  // Check if the user has the notification preference set to true
                  if (
                    (preferenceData?.editor && data.type === 'Editor') ||
                    (preferenceData?.organization && data.type === 'Organization') ||
                    (preferenceData?.system && data.type === 'System') ||
                    (preferenceData?.support && data.type === 'Support') ||
                    (preferenceData?.user && data.type === 'User') ||
                    (preferenceData?.user && data.type === 'Quote')
                  ) {
                    if(!data.readBy?.includes(this.state.app.user.uid)){
                      notification.ref.update({
                        readBy: firebase.firestore.FieldValue.arrayUnion(this.state.app.user.uid)
                      });
                    }
                  }
              });
            });
          }
      }).then(()=>{
        resolve();
      })
      .catch((error)=>{
        reject(error);
      });
    });
  },
  async markAsSeen({commit}) {
    return new Promise(async (resolve, reject) => {
      //check in collection adminNotificationPreferences if the current user uid exists
      await firebase.firestore().collection('adminNotificationPreferences').doc(this.state.app.user.uid).get().then(async (doc)=>{
        if(doc.exists){
          const preferenceData = doc.data();
          /**
         * @title - Notifications V2 (Fetch)
         * @description - This is the new way of fetching notifications, it will fetch all notifications that are of type "System"
         */
          await firebase.firestore().collection('Notifications')
            .where('type', 'in', ['Editor', 'Organization', 'System', 'User', 'Support', 'Quote'])
            .orderBy('creationDate', 'desc')
            .get()
            .then((snapshot) => {
              snapshot.docs.forEach((notification) => {
                const data = notification.data();
                  if (
                    (preferenceData?.editor && data.type === 'Editor') ||
                    (preferenceData?.organization && data.type === 'Organization') ||
                    (preferenceData?.system && data.type === 'System') ||
                    (preferenceData?.support && data.type === 'Support') ||
                    (preferenceData?.user && data.type === 'User') ||
                    (preferenceData?.user && data.type === 'Quote')
                  ) {
                    //check if this.state.app.user.uid is not already in the seenBy array
                    if(!data.seenBy?.includes(this.state.app.user.uid)){
                      notification.ref.update({
                        seenBy: firebase.firestore.FieldValue.arrayUnion(this.state.app.user.uid)
                      });
                    }
                  }
              });
          });
        }
      }).then(()=>{
        resolve();
      })
      .catch((error)=>{
        this.$swal({
          title: 'Error',
          text: error.message,
          icon: 'error',
        });
      });
    });
  
  },
  
};



export default {
  state,
  mutations,
  actions,
  getters,
};
