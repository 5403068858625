<template>
  <div>
    <va-select
      label="Question"
      v-model="selectModel"
      text-by="header"
      key-by="id"
      :options="questions"
      @input="setHiddenRule"
    />

    <va-select
      v-if="selectModel"
      label="Answer"
      v-model="answerSelectModel"
      text-by="label"
      :options="answerOptions"
      @input="setHiddenRule"
    />
  </div>
</template>

<script>
export default {
  props: ['component'],
  data() {
    return {
      selectModel: '',
      test: '',
      answerSelectModel: '',
      answerOptions: [],
    };
  },
  computed: {
    questions() {
      const selectedItemId = this.$store.state.evaluation.activeEvalItem;
      const sections = this.$store.state.evaluation.evaluation.map((section)=>{
        return section.data.questions.map((question)=>{
          return {...question, 'header': question.data.header};
        });
      });

      let quest = [];
      sections.forEach((curSet)=>{
        quest = [...quest, ...curSet];
      });
      const filtered = quest.filter((question)=>{
        return question.type !== 'openEnded' && question.id !== selectedItemId;
      });
      return filtered;
    },
  },
  created() {
    if (this.component.showRequirements) {
      const requirements = this.component.showRequirements;
      const sections = this.$store.state.evaluation.evaluation.map((section)=>{
        return section.data.questions.map((question)=>{
          return {...question, 'header': question.data.header};
        });
      });

      let quest = [];
      sections.forEach((curSet)=>{
        quest = [...quest, ...curSet];
      });
      const filtered = quest.find((question)=>{
        return question.id === requirements.questionId;
      });
      this.selectModel = filtered;

      if (typeof requirements.answerIndex !== undefined) {
        // console.log('answer index', this.selectModel.data.options);
        this.answerSelectModel = {...this.selectModel.data.options[requirements.answerIndex]};
      }
    }
  },
  methods: {
    setHiddenRule(answerIndex) {
      this.$store.dispatch('setShowRequirements', this.selectModel.id == undefined ? null : {
        questionId: this.selectModel.id,
        answerIndex: this.answerSelectModel,
      });
    },
  },
  watch: {
    selectModel() {
      if (this.selectModel) {
        this.answerOptions = this.selectModel.data.options.map((option, index)=>{
          return {'label': option.label, 'id': index};
        });
      } else {
        this.answerSelectModel = '';
      }
    },
  },
};
</script>

<style>

</style>
